<template>
  <v-container class="pa-0 ma-0">
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="dependents"
      item-key="id"
      sort-by="id"
      class="elevation-0"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- Buttons -->
          <v-row class="pa-0 ma-0 pt-3">
            <v-col
              xl="1" lg="1" md="1" sm="1"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <v-btn
                color="primary" dark class="mb-2"
                @click="formDialog=true;"
              >
              <!-- :disabled="titularId == null" -->
                Adicionar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.birthdate`]="{ item }">
        {{ item.birthdate | date }}
      </template>

      <template v-slot:[`item.countryRegister`]="{ item }">
        {{ item.countryRegister | document }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.work`]="{ item }">
        {{ item.work | phone }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="handleEdit(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

      </template>
    </v-data-table>

    <v-dialog
      v-model="formDialog"
      max-width="650"
      class="elevation-0"
      @click:outside="dependentToEdit = null;"
    >
      <v-card>
        <v-card-title class="elevation-0">
          <dependent-form-link
            v-model="dependentToEdit"
            :titularId="titularId"
            @created="formDialog=false; getAll();"
            @updated="formDialog=false; getAll();"/>
        </v-card-title>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import DependentFormLink from '@/components/contratante/pf/link/DependentFormLink';

export default {
  components: {
    // ConfirmationDialog,
    DependentFormLink,
  },
  props: {
    titularId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data: () => ({
    hashids: null,
    edit: false,
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      { text: 'Dt. Nascimento', value: 'birthdate' },
      { text: 'Email', value: 'email' },
      { text: 'Documento', value: 'countryRegister' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'work' },
      { text: 'Parentesco', value: 'kinship' },
      // { text: 'Ativo?', value: 'beneficiaryActive' },

      { text: 'Ações', value: 'actions' },
    ],
    dependents: [],
    formDialog: false,
    toChangeActive: null,
    confirmChangeActive: false,
    loading: false,
    removeLoading: false,
    dependentToEdit: null,
  }),
  computed: {
  },
  watch: {
    titularId(vl) {
      if (vl) {
        this.getAll();
      }
    },
  },
  beforeMount() {
    this.getAll();
  },
  created() {
  },
  methods: {
    getAll() {
      if (this.titularId != null) {
        this.loading = true;
        this.$store.dispatch('dependent/allLink', this.titularId).then((resp) => {
          // console.log(resp);
          this.dependents = resp;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    created() {
      setTimeout(() => {
        this.formDialog = false;
        this.beneficiary = null;
      }, 1000);
      this.getAll();
    },
    updated() {
      setTimeout(() => {
        this.formDialog = false;
        this.beneficiary = null;
      }, 1000);

      this.getAll();
    },
    handleEdit(item) {
      this.dependentToEdit = JSON.parse(JSON.stringify(item));
      this.edit = true;
      this.formDialog = true;
    },
  },
};
</script>
