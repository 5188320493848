<template>
  <v-container class="pa-0 ma-0">
    <v-row dense>
      <v-col
        xl="10" lg="10" md="10" sm="10"
        cols="10" class="mr-0"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :vid="name"
          :name="label"
          :mode="validationMode"
          :rules="`cnpj|${rules}`"
        >
          <v-text-field
            :id="name"
            v-model="internalValue"
            v-mask="'##.###.###/####-##'"
            autocomplete="off"
            :label="label"
            :hint="hint"
            :persistent-hint="persistentHint"
            :placeholder="placeholder"
            :append-icon="appendicon"
            :prefix="prefix"
            :suffix="suffix"
            type="text"
            :loading="loading"
            :disabled="disabled"
            outlined dense
            :error="errors.length > 0"
            :error-messages="errors[0]"
            @change="change"
            @blur="blur"
            @input="input"
          />
        </ValidationProvider>
      </v-col>

      <v-col
        xl="2" lg="2" md="2" sm="2"
        cols="2" class="px-0 mx-0"
        align-self="start"
      >
        <v-btn
          color="primary" icon
          @click="openVerify = true;"
          :disabled="!internalValue"
          v-if="!cnpjValid"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <VerifyCNPJModal
      v-model="cnpjValid"
      :openVerify="openVerify"
      :cnpj="cnpjToVerify"
      @cancel="cnpjToVerify = null;"
      @open="(vl) => { openVerify = vl; }"
      @company="(vl) => { $emit('company', vl); }"
    />
  </v-container>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mask } from 'vue-the-mask';
import VerifyCNPJModal from '@/components/companyEco/VerifyCNPJModal';

export default {
  directives: { mask },
  components: {
    ValidationProvider,
    VerifyCNPJModal,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    appendicon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    internalValue: '',
    cnpjToVerify: null,
    openVerify: false,
    cnpjValid: true,
    needVerify: false,
    company: null,
  }),
  watch: {
    value(vl) {
      this.internalValue = vl;
    },
    cnpjValid(vl) {
      this.$emit('valided', vl);
    },
  },
  created() {
    this.internalValue = this.value || '';
  },
  methods: {
    change(vl) {
      this.$emit('change', this.unmask(vl));
      this.cnpjToVerify = this.unmask(vl);
    },
    blur() {
      this.$emit('blur');
    },
    input(vl) {
      this.$emit('input', this.unmask(vl));
    },
    unmask(vl) {
      return vl.replace(/\D+/g, '');
    },
  },
};
</script>
