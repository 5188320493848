<template>
  <v-container>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text clas="ma-0 pa-0">

        <v-row>
          <v-col
            xs="" sm="7" md="8" lg="7"
            xl="5"
            cols="12"
          >
            <v-row>
              <v-col cols="12">
                <mb-select
                  v-model="subscription.dependents"
                  :items="dependents" name="sbscrpt_dependents"
                  itemText="name" itemValue="id" label="Dependentes" multiple
                >
                <!-- |max_value:${plan.maxLifes+1} -->
                </mb-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <mb-select
                  v-model="subscription.planId" :items="plans" name="sbscrpt_plans"
                  itemText="desc" itemValue="id" label="Planos"
                  rules="required"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pa-0 ma-0">
                <v-switch
                  v-model="subscription.buyPhysicalCard"
                  label="Incluir carteirinha"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pa-0 ma-0 pb-2">
                <v-radio-group v-model="subscription.paymentType" row >
                  <v-radio label="Cartão de Crédito" value="credit_card" />
                  <v-radio label="Boleto" value="bank_slip" />
                  <v-radio
                    :disabled="subscription.paymentType != 'pix' && subscription.id != null"
                    label="Pix" value="pix" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="['bank_slip', 'pix'].includes(subscription.paymentType)">
              <v-col cols="12">
                <mb-select
                  v-model="subscription.paymentDay"
                  :items="paymentDates" name="sbscrpt_paymentDates"
                  itemText="desc" itemValue="vl" label="Data de pagamento"
                />
              </v-col>
            </v-row>

            <v-row v-if="calcSubscription" class="pl-3">
              <!-- Bank Slip -->
              <v-col cols="12" >
                <!-- v-if="['bank_slip'].includes(subscription.paymentType)" -->
                <v-row v-if="subscription.id == null && calcSubscription.adhesionVl">
                  Adesão:  {{calcSubscription.adhesionVl | centsToReal }}* -
                  Vencimento: {{ calcSubscription.adhesionDueDate | date }}
                </v-row>
                <v-row v-if="subscription.id == null && subscription.buyPhysicalCard">
                  Carteirinha:  {{calcSubscription.cardVl | centsToReal }}* -
                  Vencimento: {{ calcSubscription.adhesionDueDate | date }}
                </v-row>
                <v-row class="py-2">
                  * Cobrados somente uma vez no momento da assinatura
                </v-row>
                <v-row class="mt-4">
                  Mensalidade: {{ calcSubscription.monthlyPaymentVl | centsToReal }} -
                  Vencimento: {{ calcSubscription.monthlyDueDate | date }}
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <!-- PaymentMehods -->
          <v-col
            xs="" sm="4" md="4" lg="4"
            xl="4"
            cols="12"
          >
            <mb-text-button
              class="pt-5 pl-5"
              v-if="(!paymentMethods || paymentMethods.length == 0) &&
                subscription.paymentType == 'credit_card' &&
                !loadingPaymentMethods"
              name="prd_btn_save" label="+ Cartão de Crédito"
              @click="showCCForm = true"
            />

            <v-progress-circular
              v-if="loadingPaymentMethods" indeterminate color="primary"
              class="mt-10 ml-5"
            />

            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12" v-for="(item, index) in paymentMethods" :key="item.id" >
              <c-card
                v-model="paymentMethods[index]"
                @card-removed="paymentMethods = []; getPaymentMethods()"
                :canRemove="subscription.paymentType != 'credit_card' ||
                  (subscription.paymentType == 'credit_card' && paymentMethods.length > 1)" />
            </v-col>
          </v-col>
        </v-row>

        <v-dialog v-model="showCCForm" max-width="350" >
          <v-card>
            <c-card-form @card-created="getPaymentMethods(); showCCForm = false;"/>
          </v-card>
        </v-dialog>
      </v-card-text>

        <v-card-actions class="pt-5">
        <v-btn
          :disabled="subscription.id != null || invalid || !planIsValid ||
            (subscription.paymentType == 'credit_card' &&
            (paymentMethods == null || paymentMethods.length == 0))" color="primary"
          @click="save"
          :loading="saveBtnLoading"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </ValidationObserver>

    <v-alert
      dense border="left" type="warning" class="ml-2 mt-2"
      v-if="!planIsValid">
      Número de dependentes excede o número de vidas do plano
    </v-alert>

  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import CCardForm from '@/components/contratante/pf/CCardForm';
import CCard from '@/components/contratante/pf/CCard';

export default {
  components: {
    ValidationObserver,
    MbSelect,
    MbTextButton,
    CCardForm,
    CCard,
  },
  props: {
    contratante: {
      type: Object,
      default: null,
      required: true,
    },
    params: {
      type: Object,
      default: null,
    },
    showing: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      // contratante: null,
      calcSubscription: null,
      subscription: {
        id: null,
        contratanteId: null,
        dependents: [],
        planId: null,
        paymentType: 'credit_card',
        paymentDay: 5,
        subestipulanteId: null,
        buyPhysicalCard: 1,
      },
      dependents: [],
      plans: [],
      paymentDates: [
        { desc: 'dia 5', vl: 5 }, { desc: 'dia 10', vl: 10 },
        { desc: 'dia 15', vl: 15 }, { desc: 'dia 30', vl: 30 },
      ],
      showCCForm: false,
      paymentMethods: [],
      loadingPaymentMethods: false,
      saveBtnLoading: false,
    };
  },
  computed: {
    planIsValid() {
      if (this.subscription.dependents.length === 0 || !this.subscription.plan) {
        return true;
      }
      return this.subscription.dependents.length + 1 <= this.subscription.plan.maxLifes;
    },
  },
  watch: {
    showing() {
      this.getPlans();
      this.getDependents();
    },
    subscription: {
      handler() {
        if (this.subscription.planId != null) {
          this.calculateSubscription();
        }
        this.getPlans();
      },
      deep: true,
    },
  },
  created() {
    this.subscription.contratanteId = this.contratante.id;
    this.subscription.subestipulanteId = this.params.se;
    this.getPlans();
    this.getDependents();
  },
  methods: {
    getContratante() {
      this.$store
        .dispatch('contratante/oneLink', {
          uId: this.params.u,
          id: this.contratanteId,
        })
        .then((contratante) => {
          this.contratante = contratante;
          this.getPlans();
          this.getDependents();
        });
    },
    getDependents() {
      if (this.contratante != null && this.contratante.userId) {
        this.loading = true;
        this.$store.dispatch('dependent/allLink', this.contratante.userId).then((resp) => {
          this.dependents = resp;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    getPlans() {
      // console.log(`getPlans subestipulanteId: ${this.subscription.subestipulanteId}`);
      if (this.contratante != null) {
        this.loading = true;
        this.$store.dispatch(
          'plan/activesByContratantePfLink',
          this.contratante.userId, this.subscription.subestipulanteId,
        ).then((resp) => {
        // console.log(resp);

          const dependents = (this.subscription.dependents != null)
            ? this.subscription.dependents.length : 0;
          const filtered = resp.filter((plan) => (plan.maxLifes >= (dependents + 1)));

          filtered.map((i) => {
            const p = i;
            p.desc = `${p.description} ${p.withAdhesion
              ? '' : '(sem adesão)'} (${this.$options.filters.centsToReal(p.value)})`;
            return p;
          });

          this.plans = filtered; // filtered;

          if (this.subscription.id == null && this.plans && this.plans.length === 1) {
            this.subscription.planId = this.plans[0].id;
          }
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    getPaymentMethods() {
      this.loadingPaymentMethods = true;
      this.$store.dispatch('paymentMethod/all').then((resp) => {
        this.paymentMethods = resp;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loadingPaymentMethods = false;
      });
    },
    calculateSubscription() {
      // console.log(`calculateSubscription subscription: ${JSON.stringify(this.subscription)}`);
      // this.subscription.contratanteId = this.contratanteId;

      this.loading = true;
      this.$store.dispatch('subscription/calculateLink', this.subscription).then((resp) => {
        this.calcSubscription = resp;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = true;
      });
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.saveBtnLoading = true;

        this.$store
          .dispatch('subscription/createLink', {
            uId: this.contratante.userId,
            subscription: this.subscription,
          })
          .then((res) => {
            this.subscription = res.subscription;
            bus.$emit('message', {
              message: 'Assinatura criada com sucesso!',
              type: 'success',
            });

            this.$emit('created', res);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
  },

};
</script>
