var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dependents,"item-key":"id","sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0 pt-3"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"1","lg":"1","md":"1","sm":"1","cols":"12"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.formDialog=true;}}},[_vm._v(" Adicionar ")])],1)],1)],1)]},proxy:true},{key:"item.birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.birthdate))+" ")]}},{key:"item.countryRegister",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.countryRegister))+" ")]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.mobile))+" ")]}},{key:"item.work",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.work))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.handleEdit(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}}],null,true)}),_c('v-dialog',{staticClass:"elevation-0",attrs:{"max-width":"650"},on:{"click:outside":function($event){_vm.dependentToEdit = null;}},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"elevation-0"},[_c('dependent-form-link',{attrs:{"titularId":_vm.titularId},on:{"created":function($event){_vm.formDialog=false; _vm.getAll();},"updated":function($event){_vm.formDialog=false; _vm.getAll();}},model:{value:(_vm.dependentToEdit),callback:function ($$v) {_vm.dependentToEdit=$$v},expression:"dependentToEdit"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }