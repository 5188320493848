<template>

  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">
        CNPJ já cadastrado
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>
          <v-col>
            O CNPJ já encontra-se cadastrado em nossa base de dados.
          </v-col>
        </v-row>
        <v-row v-if="!alreadyHasContract" no-gutters>
          <v-col>
            Favor informar a senha para verificação.
          </v-col>
        </v-row>

        <v-row v-if="!alreadyHasContract" no-gutters class="mt-5">
          <v-col>
            <mb-input-password
              v-model="password"
              name="vrfy_cnpj_password"
              label="Senha"
              :disabled="showSuccessMsg"
            />
          </v-col>
        </v-row>

        <v-row v-else no-gutters class="mt-5">
          <v-col>
            <v-alert dense text type="warning" >
              Esse CNPJ já tem um contrato. Entre em contato com o suporte!
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-if="showSuccessMsg" no-gutters class="mt-5">
          <v-alert dense text type="success" >
            Ok! Verificado com sucesso!
          </v-alert>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary" dark text @click="cancel"
          :disabled="showSuccessMsg"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary" text @click="confirm"
          :disabled="alreadyHasContract || showSuccessMsg"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mask } from 'vue-the-mask';
import bus from '@/plugins/eventBus';
import MbInputPassword from '@/components/general/inputs/MbInputPassword';

export default {
  directives: { mask },
  components: {
    MbInputPassword,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    cnpj: {
      type: String,
      default: '',
    },
    openVerify: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    password: null,
    showSuccessMsg: false,
    alreadyHasContract: false,
    res: null,
  }),
  watch: {
    cnpj(vl) {
      if (vl != null) {
        this.verifyDuplicity();
      }
    },
    openVerify(vl) {
      if (this.$refs.verifyCNPJObs) {
        this.password = null;
        this.$refs.verifyCNPJObs.reset();
      }
      if (vl === true) {
        this.dialog = true;
      }
    },
  },
  created() {
  },
  methods: {
    verifyDuplicity() {
      this.alreadyHasContract = false;

      if (this.cnpj) {
        this.$store
          .dispatch('companyEconocard/verifyByCNPJ', this.cnpj)
          .then((res) => {
            if (res) {
              if (res.contratanteId != null) {
                this.alreadyHasContract = true;
              } else {
                this.$emit('open', true);
                this.res = res;
              }
              this.dialog = true;
            } else {
              this.$emit('open', false);
              this.res = null;
            }
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          });
      }
    },
    async confirm() {
      // const isValid = await this.$refs.verifyCNPJObs.validate();

      // if (isValid) {
      this.$store
        .dispatch('user/verifyCNPJ', { cnpj: this.cnpj, password: this.password })
        .then((result) => {
          if (result && result.valid === true) {
            this.$emit('open', false);
            this.$emit('input', true);
            this.$emit('company', this.res.company);
            this.showSuccessMsg = true;

            const self = this;
            setTimeout(() => {
              this.showSuccessMsg = false;
              self.dialog = false;
            }, 2500);
          } else {
            this.$emit('company', null);
          }
        })
        .catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        });
      // }
    },
    cancel() {
      this.$emit('input', false);
      this.$emit('open', false);
      this.dialog = false;
    },
  },
};
</script>
