<template>
  <v-card class="elevation-0">
    <v-card-title>
      <span v-if="dependent && dependent.id" class="headline mb-0">
        {{ dependent.name }}
      </span>
      <span v-else class="headline mb-0">Novo Dependente</span>
    </v-card-title>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document/Kinship -->
          <v-row dense>
            <v-col
              xs="3" sm="4" md="4" lg="4"
              xl="4" cols="12" >
              <mb-input-cpf
                v-model="dependent.countryRegister" rules=""
                name="bnfc_countryRegister" label="Documento"
              />
            </v-col>
            <v-col
              xs="3" sm="4" md="4" lg="4"
              xl="4" cols="12"
            >
              <mb-select
                v-model="dependent.kinshipId"
                :items="kinshipItems"
                itemText="name"
                itemValue="id"
                name="bnfc_kinship"
                label="Parentesco"
                rules="required"
              />
            </v-col>
          </v-row>
          <!-- Name/Genre -->
          <v-row dense>
            <v-col
              xs="3" sm="8" md="8" lg="8"
              xl="8" cols="12"
            >
              <mb-input-text
                v-model="dependent.name"
                name="bnfc_name" label="Nome"
                rules="required|max:60"
              />
            </v-col>
            <v-col
              xs="4" sm="4" md="4" lg="4"
              xl="4" cols="12"
            >
              <mb-select
                v-model="dependent.genre"
                :items="sexItems"
                itemText="text"
                itemValue="vl"
                name="bnfc_sex"
                label="Sexo"
                rules=""
              />
            </v-col>
          </v-row>
          <!-- Email/Birthdate -->
          <v-row dense>
            <v-col
              xs="3" sm="8" md="8" lg="8"
              xl="8" cols="12"
            >
              <mb-input-text
                v-model.trim="dependent.email"
                name="bnfc_email" label="Email" rules=""
              />
            </v-col>
            <v-col
              xs="4" sm="4" md="4" lg="4"
              xl="4" cols="12"
            >
              <mb-date
                v-model="dependent.birthdate"
                name="birthdate" rules="required"
                label="Dt Nascimento"
                :allowedDates="(vl) => { return $moment(vl).isBefore($moment()) }"
              />
            </v-col>
          </v-row>
          <!-- Mobile/Phone -->
          <v-row dense>
            <v-col
              xs="5" sm="5" md="5" lg="5"
              xl="5" cols="12"
            >
              <mb-input-phone
                v-model="dependent.mobile" rules=""
                name="bnfc_mobile" label="Celular"
              />
            </v-col>
            <v-col
              xs="5" sm="5" md="5" lg="5"
              xl="5" cols="12"
            >
              <mb-input-phone
                v-model="dependent.work" rules=""
                name="bnfc_work" label="Telefone"
              />
            </v-col>
          </v-row>
        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCpf from '@/components/general/inputs/MbInputCpf';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbDate from '@/components/general/inputs/MbDate';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbInputCpf,
    MbSelect,
    MbDate,
    MbTextButton,
  },
  props: {
    titularId: {
      type: Number,
      default: null,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      dependent: null,
      defaultDependent: {
        countryRegister: null,
        name: null,
        genre: null,
        email: null,
        birthdate: null,
        mobile: null,
        work: null,
        kinshipId: null,
      },
      sexItems: [
        { vl: 'female', text: 'Feminino' },
        { vl: 'male', text: 'Masculino' },
      ],
      kinshipItems: [],
    };
  },
  computed: {
  },
  watch: {
    value(vl) {
      this.dependent = vl || JSON.parse(JSON.stringify(this.defaultDependent));
      this.$refs.observer.reset();
    },
  },
  created() {
    this.getKinships();
    this.dependent = this.value || JSON.parse(JSON.stringify(this.defaultDependent));
  },
  destroyed() {
    this.dependent = JSON.parse(JSON.stringify(this.defaultDependent));
  },
  methods: {
    save() {
      if (this.dependent.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('dependent/createLink', {
            titularId: this.titularId,
            dependent: this.dependent,
          })
          .then((dependentSaved) => {
            bus.$emit('message', {
              message: 'Dependente criado com sucesso!',
              type: 'success',
            });
            this.$emit('created');
            this.$emit('input', dependentSaved);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('dependent/updateLink', {
            titularId: this.titularId,
            dependent: this.dependent,
          })
          .then((dependentSaved) => {
            bus.$emit('message', {
              message: 'Dependente atualizado com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
            this.$emit('input', dependentSaved);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    getKinships() {
      this.kinshipLoading = true;
      this.$store.dispatch('kinship/all')
        .then((resp) => {
          this.kinshipItems = resp;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.kinshipLoading = false;
        });
    },
    cancel() {
      this.$emit('cancel');
      this.dependent = JSON.parse(JSON.stringify(this.defaultDependent));
      this.$refs.observer.reset();
      this.$router.push({ name: 'dependent-list' });
    },
  },
};
</script>
